<template>
  <div class="d-flex flex-column align-center justify-center">
    <v-progress-linear indeterminate color="info" />
    <h3>Carregando avaliação...</h3>
  </div>
</template>

<script>
export default {
  name: "Loading",
};
</script>

<style></style>
